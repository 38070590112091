<template>
    <NavBar />
</template>

<script>
import NavBar from '../layouts/NavBar'
    export default {
        components:{
            NavBar,
        }
    }
</script>

<style scoped>

</style>